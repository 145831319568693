import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import { Link, graphql } from 'gatsby';
import SEO from '../components/utility/SEO'

const LandingWrapper = styled(BackgroundImage)`
  opacity: 1!important;
  background-size: cover;
  background-color: rgba(26,26,26,0.65);
  height:20rem;

  display:flex;
  justify-content:center;
  align-items:center;
  color:var(--color-white);
  h1 {
    letter-spacing:3px;
  }
`;

const PostWrapper = styled(motion.div)`
  display:grid;
  max-width:1000px;
  margin: auto;
  h2 { text-align:center; }
  padding: 5vh 5vw;
  .error {
    grid-template-columns: 100%;
    text-align:center;
  }
`
const Filter = styled.div`
  position:relative;
  height: 3rem;
  margin: 0.5rem 0;
  z-index:1000;
`
const FilterContainer = styled(motion.div)`
  display:inline-block;
  position:absolute;
  overflow:hidden;
  .filter-label {
    padding: 0.25rem .5rem;
    border: 1px solid var(--color-black);
    border-radius: 1rem;
    background-color: var(--color-white);
    width:fit-content;
  }
  .filter-item p {
    margin:0;
    cursor:pointer;
    transition: var(--transition);
  }
  .filter-category:hover, .filter-category:focus {
    p {
      color:var(--color-accent-blue);
      transition: var(--transition);
    }
  }
  .filter-label p {
    display:inline-block;
    margin: 0 0.15rem;
    cursor:default;
  }
  .filter-label .filter-keyword {
    cursor:pointer;
  }
  .selected-category {
    height: 0.25rem;
    width: 0.25rem;
    border: 1px solid var(--color-black);
    padding: 0.1rem;
    display: inline-block;
    margin-right: 0.5rem;
    transition:var(--transition);
  }
  .selected {
    border:1px solid transparent;
    transition:var(--transition);
    background-color: var(--color-accent-blue);
  }
  .close-selected-category {
    display:inline;
    margin: 0 3px;
    padding: 0.1rem 0.2rem;
    border: 1px solid black;
    border-radius: 10px;
    font-size: 0.5rem;
    line-height: 0.5rem;
    color: var(--color-gray);
    transition: var(--transition);
    vertical-align: middle;
    cursor:pointer;
  }
  .close-selected-category:hover {
    transition: var(--transition);
    color: var(--color-black);
  }
  .filter-list {
    margin-top:0.5rem;
    padding: 0.25rem .5rem;
    border: 1px solid var(--color-black);
    border-radius: 1rem;
    background-color: var(--color-white);
    width:fit-content;
  }
`
const PostContainer = styled(motion.div)`
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap:1rem;
  justify-content: space-around;
  @media only screen and (min-width:550px) and (max-width: 700px) {
    grid-template-columns: 85%;
  }
  @media only screen and (min-width: 700px) {
    grid-template-columns: 47.5% 47.5%;
  }
  @media only screen and (min-width: 1000px) {
    grid-template-columns: 32% 32% 32%;
    justify-content: space-between;
  }

  .post {
    display:grid;
    grid-auto-flow:row;
    border-radius:4px;
    border: 1px solid rgb(224, 224, 224);
    box-shadow: rgb(0 0 0 / 20%) 0px 0px 0px 0px, rgb(0 0 0 / 14%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 0px 0px 0px;
    cursor:pointer;
    transition:var(--transition);
  }
  @media only screen and (min-width: 1000px) {
    grid-template-columns: 32% 32% 32%;
  }
  a:hover {
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
    color:inherit;
  }
  .post:hover, .post:active {
    transform: scale(1.05);
    transition:var(--transition);
  }
  .gatsby-image-wrapper {
    border-radius:4px 4px 0px 0px;
    height:100%;
  }
`
const PostContent = styled.div`
  padding:0.5rem 0.5rem;
  .metawrapper {
    display:flex;
    justify-content:space-between;
    p{
      display:inline-block;
      font-size:0.8rem;
    }
  }
  h3 {
    padding: 0.5rem;
    letter-spacing: 2px;
    text-align: center;
    font-weight: 300;
  }
  .post-description {
    line-height:1.5rem;
  }
`


const Blog = ({data}) => {
  const { sanitySettings } = data
  const posts = data.allSanityPost.nodes
  // Two map functions return array of arrays of programs. (2d array matrix). Reducer function consolidates all values of 2nd matrix dimension to a single aray
  const programsArray = posts.map(post =>  post.postData.postAttributes.program.map(program => program.title))
  const reducedProgramsArray = programsArray.reduce((initialArray, nextvalue) => initialArray.concat(nextvalue));
  // Prototype Array.from creates a new array. Set() is a constructor that returns all values, but only Unique values
  const categoriesArray = Array.from(new Set(reducedProgramsArray))

  const [state, setState] = useState({
    activePosts: posts,
    categories: categoriesArray,
    selectedFilters: [],
    filterExpanded: false
  })
  const { activePosts, categories, selectedFilters, filterExpanded } = state

  useEffect(() => {
    let filteredPosts = posts.filter( post => {
      let postCategories = post.postData.postAttributes.program.map(program => program.title)
      let currentFilters = selectedFilters

      const containsAll = (arr1, arr2) => arr2.every(arr2Item => arr1.includes(arr2Item))

      if(containsAll(postCategories, currentFilters)) {
        return post
      } else {
        return false
      }
    })

    if(selectedFilters.length !== 0) {
      setState({ ...state, activePosts: filteredPosts });
    } else {
      setState({ ...state, activePosts: posts });
    }

  }, [selectedFilters.join(",")])

  const toggleFilterExpanded = () => {
    setState(prevState => ({
      ...state,
      filterExpanded: !prevState.filterExpanded
     }))
  }
  const toggleCategory = (category) => {
    if( !selectedFilters.includes(category) ) {
      setState(prevState => {
        let filterListCopy = prevState.selectedFilters
        filterListCopy.push(category)
        return {...prevState, selectedFilters: filterListCopy }
      })
     } else {
        setState(prevState => {
        let filterListCopy = prevState.selectedFilters
        let newSelection = filterListCopy.filter(item => item != category)
        return {...prevState, selectedFilters: newSelection }
      })
    }
  }
  const removeCategory = (category) => {
    setState(prevState => {
      let filterListCopy = prevState.selectedFilters
      let newSelection = filterListCopy.filter(item => item != category)
      return {...prevState, selectedFilters: newSelection }
    })
  }
  const filterContainerAnimations = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15
      }
    },
    exit: {
      opacity:0,
      transition: {
        duration: 0.5,
        staggerChildren: 0.05,
        staggerDirection: -1,
        when: "beforeChildren"
      }
    }
  }
  const filterItemAnimations = {
    hidden: { opacity: 0, height:0 },
    show: { opacity: 1, height: "auto" },
    exit: { opacity: 0, height:0 },
  }

  const container = {
    hidden: { opacity: 0},
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.35,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        staggerChildren: 0,
        duration: .1
      }
    }
  };
  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  return (
    <>
      <SEO title={'X-Factor Blog'} description={"Fitness, Wellness, and Nutrition articles written by our team."} keywords={sanitySettings.keywords} />
      <LandingWrapper fluid={data.sanityBlogpage.blogBackgroundImage.asset.fluid}>
        <h1>X-Factor Blog</h1>
      </LandingWrapper>
      <PostWrapper>
        <Filter>
          <FilterContainer >
            {/* Label for the container */}
            <motion.div layout className="filter-label filter-item">
              <p className="filter-keyword" onClick={() => toggleFilterExpanded()}>Filter: </p>
              <AnimatePresence>
                {selectedFilters.map((filter, index) => (
                  <motion.p initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} layout key={`selected-category-${index}`}><span onClick={() => removeCategory(filter)} className="close-selected-category">X</span>{filter}</motion.p>
                ))}
              </AnimatePresence>
            </motion.div>
            {/* Expanded list items. Hidden by default */}
            <AnimatePresence initial={false}>
              {filterExpanded && (
                <motion.div
                  className="filter-list"
                  layout
                  variants={filterContainerAnimations}
                  initial="hidden"
                  animate="show"
                  exit="exit">
                  {categories.map((category, index) => (
                    <motion.div key={`category-${index}`} layout variants={filterItemAnimations} className="filter-category filter-item" whileTap={{scale: 0.9}} onClick={() => toggleCategory(category)}>
                      <motion.p>
                        <span className={selectedFilters.includes(category) ? 'selected-category selected' : 'selected-category'}></span>{category}
                      </motion.p>
                    </motion.div>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </FilterContainer>
        </Filter>
        <PostContainer variants={container} initial="hidden" animate="show" className={activePosts.length == 0 && "error"}>
          {activePosts.length !== 0 ? activePosts.map((post, index) => (
            <motion.div key={index} variants={item}>
              <Link to={post.slug.current} className="post" >
                <div key={index}>
                  <Img
                    fluid={post.mainImage && post.mainImage.asset.fluid}
                    style={{ maxHeight: '150px' }}
                    imgStyle={{ objectFit: 'cover', objectPosition: '50% 50%' }}
                  />
                </div>
                <PostContent>
                  <h3>{post.title}</h3>
                  {/* <h4>{post.description}</h4> */}
                  <div className="metawrapper">
                    <p>by: <em>{post.postData.postAuthor.coach.name}</em></p>
                    <p>{post._createdAt}</p>
                  </div>
                  <p className="post-description">{post.excerpt}</p>
                </PostContent>
              </Link>
            </motion.div>
          )) : (
            <motion.h3>No posts meet filter criteria.</motion.h3>
          )}
        </PostContainer>
      </PostWrapper>
    </>
  )
}

export default Blog

export const query = graphql`
query postsQuery {
  sanitySettings {
    keywords
  }
  sanityBlogpage {
    blogBackgroundImage {
      asset {
        fluid(maxWidth:1920) {
          ...GatsbySanityImageFluid_withWebp
        }
      }
    }
  }
  allSanityPost {
    nodes {
      _createdAt(formatString: "MMMM Do YYYY")
      title
      id
      description
      slug {
        current
      }
      excerpt
      mainImage {
        caption
        asset {
          fluid(maxWidth: 300, maxHeight: 300) {
            ...GatsbySanityImageFluid
          }
        }
      }
      postData {
        postAuthor {
          coach {
            name
          }
        }
        postAttributes {
          program {
            slug {
              current
            }
            title
          }
        }
      }
    }
  }
}

`
